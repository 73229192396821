// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  background-color: $tbf-blue;
  border-radius: 4px;
}

.root:global(.ant-collapse > .ant-collapse-item > .ant-collapse-header) {
  color: $tbf-white;
}
