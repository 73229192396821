// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;

  position: fixed;
  top: 0;
  right: -100px;
  padding: 20px;
  transition: transform .15s ease-in-out;

  &.visible {
    transform: translate3d(-100px,0,0);
  }
}
