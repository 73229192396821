// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.loading {
  align-items: center;
  color: $tbf-blue;
  display: flex;
  flex-direction: column;
  font-size: $medium-font-size;
  height: 100vh;
  justify-content: center;
  margin-top: -60px;
  text-align: center;
  width: 100%;
}

.error {
  align-items: center;
  background-color: $room-dirty;
  border-radius: 4px;
  color: $tbf-white;
  display: flex;
  font-size: $medium-font-size;
  justify-content: center;
  min-height: 30px;
  padding: 10px;
  text-align: center;
  width: 100%;
}
