// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  align-items: center;
  color: $tbf-blue;
  display: flex;
  font-size: $medium-font-size;
  flex-direction: column;
  font-weight: bold;
  height: 100vh;
  justify-content: center;
  margin-top: -90px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.redirect_button {
  align-items: center;
  display: flex;
  font-size: 20px;
  justify-content: center;
  margin-top: 30px;
}

.number {
  font-size: $large-font-size;
}
