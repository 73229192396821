// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;

  margin: 0;
  padding: 0;
  color: rgba(0,0,0,0.85);
  font-size: 14px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #f0f0f0;

  box-shadow: 0 3px 4px 0 rgb(0 0 0 / 12%);
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0;

  transition: all .2s ease-in-out;

  border-bottom: 1px solid #f0f0f0;
  color: rgba(255,255,255,0.85);

  &.clean {
    background-color: $room-clean;
  }

  &.dirty {
    background-color: $room-dirty;
  }

  &.inspected {
    background-color: $room-inspected;
  }

  &.broken {
    background-color: $room-broken;
  }

  &.do_not_disturb {
    background-color: $room-dnd;
  }
}

.room_title {
  border-right: 1px solid $tbf-white;
  text-align: center;
  width: 85%;
  & > h2:global(.ant-typography) {
    margin-bottom: 0;
    color: rgba(255,255,255,0.85);
    font-weight: 300;
    font-size: 36px;
  }
}

.change_status_wrapper {
  text-align: center;
  width: 15%;
}

.change_status_btn {
  border: none;
  color: rgba(255,255,255,0.85);
  cursor: pointer;
  height: 100%;
  padding: 10px;
  width: 100%;
}

.content {
  padding: 8px;
  line-height: 2;
}

.change_status_menu {
  & > li {
    position: relative;
    padding: 12px 24px;

    margin: 2px 0;

    > div {
      position: absolute;
      width: 5px;
      height: 100%;
      top:0;
      left:0;
    }

    > div.dirty {
      background-color: $room-dirty;
    }

    > div.clean {
      background-color: $room-clean;
    }

    > div.inspected {
      background-color: $room-inspected;
    }

    > div.do_not_disturb {
      background-color: $room-dnd;
    }

    > div.broken {
      background-color: $room-broken;
    }
  }
}

.room_status {
  text-transform: capitalize;
}

