// Generated with util/create-component.js
@import "../../../variables.scss";
@import "../../../typography.scss";
@import "../../../common.scss";

.root {
  @include font-defaults;
}

.room_item_container {
  border-radius: 4px;
  cursor: pointer;
  float: left;
  margin: 1.66%;
  overflow: hidden;
  padding-bottom : 30%;
  position: relative;
  width: 30%;

  @include breakpoint ($sm) {
    margin: 1%;
    padding-bottom: 18%;
    width: 18%;
  }

  &.clean {
    background-color: $room-clean;
  }

  &.dirty {
    background-color: $room-dirty;
  }

  &.inspected {
    background: $room-inspected;
  }

  &.broken {
    background-color: $room-broken;
  }

  &.doNotDisturb {
    background-color: $room-dnd;
  }
}

.room_item_wrapper {
  color: $tbf-white;
  height: 100%;
  position: absolute;
  width: 100%;
}

.room_item_content {
  display: table;
  height: 100%;
  width: 100%;
}

.room_item_details{
  display: table-cell;
  height: 100%;
  position: relative;
  vertical-align: middle;
  width: 100%;
}

.room_number {
  font-size: $large-font-size;
  text-align: center;

  &.medium_number {
    font-size: $medium-font-size;
  }

  &.small_number {
    font-size: $small-font-size;
  }
}

.icons_wrapper {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  position: absolute;
  padding: 8px;
  :global(svg) {
    fill: #fff;
  }
  @include breakpoint ($xs) {
    padding: 5px;
  }
}

.room_nights {
  align-items: center;
  display: flex;
  font-size: 16px;
  position: absolute;
  padding: 8px;
  right: 0;
  top: 0;

  @include breakpoint ($xs) {
    padding: 5px;
    font-size: $small-font-size;
  }
}