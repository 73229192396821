// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;

  height: 100%;
  min-height: 140px;
}

.root :global(.ant-card-head) {
  background: rgba(0,0,0,0.1);
}

.root :global(.ant-card-head-title) {
  font-size: 16px;
}

.guests {
  display: block;
  font-weight: 600;
}

.name {
  font-weight: 400;
  font-style: italic;
  font-size: 0.8em;
  opacity: 0.8;
}

.dates {
  display: block;
  opacity: 0.8;
}
