// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  padding: 24px;
}

.login_container {
  max-width: 400px;
  margin: 0px auto;
}

.logo_container {
  text-align: center;
  padding: 16px;
}

.title {
  padding-top: 16px;
  text-transform: uppercase;
  font-weight: 200 !important;
}
