// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";
@import "../../common.scss";

.root {
  @include font-defaults;
}

.content {
  margin: 16px;
  max-width: 768px;

  @include breakpoint ($sm) {
    margin: 32px auto;
  }
}
