// Generated with util/create-component.js
@import '../../variables.scss';
@import '../../typography.scss';
@import '../../common.scss';

.root {
  @include font-defaults;

  :global(.ant-input-number-handler-wrap) {
    display: none;
  }
}

.input_icon {
  color: rgba(0, 0, 0, 0.25);
}

.error_feedback {
  display: block;
  margin-bottom: 8px;
}

.login_button {
  width: 230px;
  margin-top: 16px;
  margin-bottom: 8px;

  span {
    font-weight: 500;
  }
}

.confirmation_code_description {
  font-size: 16px;
  padding-bottom: 10px;
  white-space: pre-wrap;
}

.otp_code_input {
  width: 100%;
}
