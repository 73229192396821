// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
  align-items: center;
  color: $tbf-blue;
  display: flex;
  flex-direction: column;
  font-size: $medium-font-size;
  height: 100vh;
  justify-content: center;
  margin-top: -90px;
  text-align: center;
  width: 100%;
}