/* Variables */
$dark: #131111;

$tbf-blue: #2482d1;
$tbf-white: #ffffff;
$room-dirty: #ff5f61;
$room-clean: #62d74a;
$room-inspected: #1f8ef6;
$room-broken: #fdf200;
$room-dnd: #fd8e27;
