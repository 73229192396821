$font-family: "Avenir Next", Helvetica, Arial, sans-serif;
$small-font-size: 12px;
$medium-font-size: 18px;
$large-font-size: 36px;

@mixin font-defaults {
  font-family: $font-family;
}

@mixin heading {
  @include font-defaults;

  font-size: 40px;
  font-weight: bold;
}