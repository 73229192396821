// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 12px;
  background-color: $tbf-blue;
  box-shadow: 0 2px 8px #f0f1f2;
}

.nav_button {
  color: #fff;
  text-decoration: none;
}

.left_content {
  margin-left: auto;

  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer_item {
  margin-bottom: 10px;
}

.logo {
  height: 45px;
  left: 50%;
  margin-left: -50px;
  position: absolute;
  text-align: center;
  width: 100px;
  :global(.cls-2) {
    fill: #fff;
  }
  :global(.cls-1) {
    fill: #fff;
  }
}