// Generated with util/create-component.js
@import "../../variables.scss";
@import "../../typography.scss";

.root {
  @include font-defaults;
}

.filters {
  margin: 20px;
  text-align: center;
}

.helper_message {
  font-size: $medium-font-size;
  text-align: center;
}